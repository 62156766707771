export const ENUM_JOB_STATUS_LABEL = {
  draft: 'Daft',
  published: 'Public',
  closed: 'Closed'
}
export const ENUM_JOB_STATUS = {
  draft: 0,
  published: 1,
  closed: 2
}
export const ENUM_JOB_EMPLOYMENT_TYPE = {
  part_time: 'part_time',
  full_time: 'full_time',
  internship: 'internship'
}
export const ENUM_JOB_EXERIENCE = {
  intern: 'intern',
  manager: 'manager',
  director: 'director',
  junior: 'junior',
  senior: 'senior'
}
export const ENUM_JOB_FREEC_STATUS = {
  pending: 'pending',
  approved: 'approved',
  expired: 'expired',
  rejected: 'rejected'
}
export const ENUM_JOB_FREEC_STATUS_NUMBER = {
  pending: 0,
  approved: 1,
  rejected: 2,
  expired: 3
}
export const ENUM_COUNT_JOB = {
  published: 'all_published',
  reviewing: 'all_reviewing',
  closed: 'all_closed',
  rejected: 'all_rejected',
  expired: 'all_expired'
}
export const LIST_STATIC_OPTIONS_MAP = {
  1: { id: 1, min_salary: 0, max_salary: 500, salary_currencv: 'USD' },
  2: { id: 2, min_salary: 500, max_salary: 1000, salary_currencv: 'USD' },
  3: { id: 3, min_salary: 1000, max_salary: 2000, salary_currencv: 'USD' },
  4: { id: 4, min_salary: 2000, max_salary: 3000, salary_currencv: 'USD' },
  5: { id: 5, min_salary: 3000, max_salary: 4000, salary_currencv: 'USD' },
  6: { id: 6, min_salary: 0, max_salary: 10000000, salary_currencv: 'VND' },
  7: {
    id: 7,
    min_salary: 10000000,
    max_salary: 20000000,
    salary_currencv: 'VND'
  },
  8: {
    id: 8,
    min_salary: 20000000,
    max_salary: 30000000,
    salary_currencv: 'VND'
  },
  9: {
    id: 9,
    min_salary: 30000000,
    max_salary: 40000000,
    salary_currencv: 'VND'
  },
  10: {
    id: 10,
    min_salary: 40000000,
    max_salary: 50000000,
    salary_currencv: 'VND'
  }
}
export const LIST_EXPECTED_SALARY_OPTIONS_MAP = {
  // 1: { id: 1, expexted_salary: 500, salary_currencv: 'USD' },
  2: { id: 2, expected_salary: 500, salary_currencv: 'USD' },
  3: { id: 3, expected_salary: 1000, salary_currencv: 'USD' },
  4: { id: 4, expected_salary: 2000, salary_currencv: 'USD' },
  5: { id: 5, expected_salary: 3000, salary_currencv: 'USD' },
  6: { id: 6, expected_salary: 0, salary_currencv: 'VND' },
  7: { id: 7, expected_salary: 10000000, salary_currencv: 'VND' },
  8: { id: 8, expected_salary: 20000000, salary_currencv: 'VND' },
  9: { id: 9, expected_salary: 30000000, salary_currencv: 'VND' },
  10: { id: 10, expected_salary: 40000000, salary_currencv: 'VND' }
}

export const LIST_CLIENT_STATUS = [
  { id: 0, name: 'Pending' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'De-active' }
  // { id: 3, name: 'Priority' }
]

export const LIST_CLIENT_SOURCES = [
  { id: 0, name: 'Facebook' },
  { id: 1, name: 'Referral' },
  { id: 2, name: 'Linkedin' },
  { id: 3, name: 'Contact Request' },
  { id: 4, name: 'Platform' },
  { id: 5, name: 'Event' },
  { id: 6, name: 'Job Site' },
  { id: 7, name: 'MKT Activities' },
  { id: 8, name: 'Customer Happy' },
  { id: 9, name: 'Hub Spot' },
  { id: 10, name: 'Others' }
]

export const JOB_STATUS_PUBLISH = 'published'
export const JOB_STATUS_DRAFT = 'draft'
export const JOB_STATUS_REVIEWING = 'reviewing'
export const JOB_STATUS_CLOSED = 'closed'
export const JOB_STATUS_REJECTED = 'rejected'
export const JOB_STATUS_EXPIRED = 'expired'

export const JOB_STATUS_MAP = {
  [JOB_STATUS_PUBLISH]: {
    name: 'Published',
    name_vn: 'Đang tuyển',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: ENUM_JOB_STATUS.published,
    count: ENUM_COUNT_JOB.published,
    order_by: 'approved_date',
    empty_title: 'There are no published jobs',
    empty_title_vn: 'Không có việc làm đang tuyển'
  },

  [JOB_STATUS_REVIEWING]: {
    name: 'Reviewing',
    name_vn: 'Đang duyệt',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.pending,
    status: ENUM_JOB_STATUS.published,
    count: ENUM_COUNT_JOB.reviewing,
    order_by: 'created_at',
    empty_title: 'There are no reviewing jobs',
    empty_title_vn: 'Không có việc làm đang duyệt'
  },
  [JOB_STATUS_CLOSED]: {
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed,
    count: ENUM_COUNT_JOB.closed,
    order_by: 'closed_date',
    empty_title: 'There are no closed jobs',
    empty_title_vn: 'Không có việc làm đã đóng'
  },
  [JOB_STATUS_REJECTED]: {
    name: 'Rejected',
    name_vn: 'Từ chối',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.rejected,
    count: ENUM_COUNT_JOB.rejected,
    order_by: 'rejected_date',
    empty_title: 'There are no rejected jobs',
    empty_title_vn: 'Không có việc làm bị từ chối'
  }
}

export const JOB_ORDERS_STATUS_MAP = {
  [JOB_STATUS_PUBLISH]: {
    name: 'Published',
    name_vn: 'Đang tuyển',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: ENUM_JOB_STATUS.published
  },
  [JOB_STATUS_REVIEWING]: {
    name: 'Reviewing',
    name_vn: 'Đang duyệt',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.pending,
    status: ENUM_JOB_STATUS.published
  },
  [JOB_STATUS_CLOSED]: {
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed
  },
  [JOB_STATUS_REJECTED]: {
    name: 'Rejected',
    name_vn: 'Từ chối',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.rejected,
    status: ENUM_JOB_STATUS.published
  }
}

export const COMPANY_JOB_ORDERS_STATUS_MAP = {
  [JOB_STATUS_PUBLISH]: {
    name: 'Published',
    name_vn: 'Đang tuyển',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: ENUM_JOB_STATUS.published
  },
  [JOB_STATUS_CLOSED]: {
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed,
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved
  }
}
const CANDIDATE_STATUS_INCOMPLETE = 0
const CANDIDATE_STATUS_COMPLETE = 1
const CANDIDATE_STATUS_BIN = 2

export const CANDIDATE_STATUS_MAP = {
  [CANDIDATE_STATUS_INCOMPLETE]: {
    name: 'Incomplete',
    id: CANDIDATE_STATUS_INCOMPLETE,
    name_vn: 'Chưa hoàn thành'
  },
  [CANDIDATE_STATUS_COMPLETE]: {
    name: 'Complete',
    id: CANDIDATE_STATUS_COMPLETE,
    name_vn: 'Hoàn thành'
  },
  [CANDIDATE_STATUS_BIN]: {
    name: 'Bin',
    id: CANDIDATE_STATUS_BIN,
    name_vn: 'Loại'
  }
}

export const CONSULTANTS_JOB_TYPES = [
  { id: 0, name: 'Non IT' },
  { id: 1, name: 'IT' }
]
export const CONTACT_FORM_POSITION = [
  { id: 0, name: 'CEO/ Founder/ Owner', name_vn: 'CEO / Founder / Sở hữu' },
  {
    id: 1,
    name: 'Director /  CFO / CTO / CPO/ VP',
    name_vn: ' Giám đốc / CFO / CTO / CPO / VP'
  },
  { id: 2, name: 'Manager', name_vn: 'Quản lý' },
  { id: 3, name: 'Staff', name_vn: 'Nhân viên' }
]
export const CONTACT_FORM_COMPANY_SIZE = [
  { id: 0, name: '0 - 10 employees', name_vn: '0 - 10 nhân sự' },
  { id: 1, name: '11- 50 employees', name_vn: '11- 50 nhân sự' },
  { id: 2, name: '51 - 100 employees', name_vn: '51 - 100 nhân sự' },
  { id: 3, name: '101 - 500 employees', name_vn: '101 - 500 nhân sự' },
  { id: 4, name: '501 - 1000 employees', name_vn: '501 - 1000 nhân sự' },
  { id: 5, name: '1000+ employees', name_vn: '1000+ nhân sự' }
]

export const REJECT_REASON_CANDIDATE = [
  { id: 0, label: 'not_a_fit', name: 'Not a fit', name_vn: 'Không phù hợp' },
  { id: 1, label: 'over_qualify', name: 'Over Qualify', name_vn: 'Vượt yêu cầu' },
  { id: 2, label: 'spam', name: 'Spam', name_vn: 'Spam' },
  { id: 3, label: 'lack_of_skills', name: 'Lack of skills', name_vn: 'Thiếu kinh nghiệm' },
  { id: 4, label: 'hired_elsewhere', name: 'Hired elsewhere', name_vn: 'Đã tuyển cty khác' },
  { id: 5, label: 'over_salary_range', name: 'Over salary range', name_vn: 'Vượt mức lương' },
  { id: 6, label: 'duplication', name: 'Duplication', name_vn: 'Trùng ứng viên' },
  { id: 7, label: 'pending', name: 'Pending', name_vn: 'Tạm ngừng' },
  { id: 8, label: 'not_open', name: 'Not Open', name_vn: 'Chưa mở' },
  { id: 9, label: 'failed_on_probation', name: 'Fail on probation', name_vn: 'Thử việc thất bại' },
  { id: 11, label: 'cancel_offer', name: 'Cancel Offer', name_vn: 'Hủy Offer' },
  { id: 10, label: 'others', name: 'Others', name_vn: 'Khác' }
]
export const INTERVIEW_ARRAY_TYPE = [
  {
    id: 0,
    name: 'Phone Interview'
  },
  {
    id: 1,
    name: 'Online Interview'
  },
  {
    id: 2,
    name: 'On-site Interview'
  },
  {
    id: 3,
    name: 'Final Interview'
  }
]
export const INTERVIEW_DURATION = [
  {
    id: 0.25,
    name: '00:15'
  },
  {
    id: 0.5,
    name: '00:30'
  },
  {
    id: 0.75,
    name: '00:45'
  },
  {
    id: 1,
    name: '01:00'
  },
  {
    id: 1.25,
    name: '01:15'
  },
  {
    id: 1.5,
    name: '01:30'
  },
  {
    id: 1.75,
    name: '01:45'
  },
  {
    id: 2,
    name: '02:00'
  },
  {
    id: 2.25,
    name: '02:15'
  },
  {
    id: 2.5,
    name: '02:30'
  },
  {
    id: 2.75,
    name: '02:45'
  },
  {
    id: 3,
    name: '03:00'
  }
]

export const INTERVIEW_TYPE = {
  0: { name: 'Phone Interview', name_vn: 'Phỏng vấn qua điện thoại' },
  1: { name: 'Online Interview', name_vn: 'Phỏng vấn online' },
  2: { name: 'On-site Interview', name_vn: 'Phỏng vấn ở office' },
  3: { name: 'Final Interview', name_vn: 'Phỏng vấn vòng cuối' }
}

export const MONTH_FILTER = [
  { id: 1, name: 'Jan' },
  { id: 2, name: 'Feb' },
  { id: 3, name: 'Mar' },
  { id: 4, name: 'Apr' },
  { id: 5, name: 'May' },
  { id: 6, name: 'Jun' },
  { id: 7, name: 'Jul' },
  { id: 8, name: 'Aug' },
  { id: 9, name: 'Sep' },
  { id: 10, name: 'Oct' },
  { id: 11, name: 'Nov' },
  { id: 12, name: 'Dec' }
]

export const JOB_ORDER_TYPES = [
  { id: 0, name: 'Non-IT', key: 'non_it' },
  { id: 1, name: 'IT', key: 'it' }
]
export const JOB_ORDER_TYPE_ID_NAME_MAP = JOB_ORDER_TYPES.reduce((result, item) => ({ ...result, [item.id]: item.name }), {})

export const JOB_ORDER_TYPE_KEY_NAME_MAP = JOB_ORDER_TYPES.reduce((result, item) => ({ ...result, [item.key]: item.name }), {})

export const PROBATION_DURATION = [
  {
    id: 0,
    name: 'None',
    name_vn: 'None'
  },
  { id: 1, name: '1 month', name_vn: '1 tháng' },
  { id: 2, name: '2 months', name_vn: '2 tháng' },
  { id: 3, name: '3 months', name_vn: '3 tháng' },
  { id: 4, name: '4 months', name_vn: '4 tháng' },
  { id: 5, name: '5 months', name_vn: '5 tháng' },
  { id: 6, name: '6 months', name_vn: '6 tháng' }
]

export const PLACEMENT_STATUS = [
  { id: 0, name: 'Pending' },
  { id: 1, name: 'Onboarding' },
  { id: 2, name: 'Invoiced' },
  { id: 3, name: 'Paid' },
  { id: 4, name: 'Canceled' }
]

export const ONBOARD_STATUS = [
  { id: 0, name: 'Onboarding' },
  { id: 1, name: 'Probation' },
  { id: 2, name: 'Passed' },
  { id: 3, name: 'Failed' }
]

export const OFFER_STATUS = [
  { id: 0, name: 'Waiting for feedback' },
  { id: 1, name: 'Rejected' },
  { id: 2, name: 'Accepted' }
]
export const COMPANY_TYPE = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Client' },
  { id: 2, name: 'Company' }
]

export const JOB_ORDER_STAGE = [
  { id: 1, name: 'Applied' },
  { id: 2, name: 'Sourced' },
  { id: 3, name: 'Screening' },
  { id: 8, name: 'Send to Client' },
  { id: 5, name: 'Interview' },
  { id: 6, name: 'Offer' },
  { id: 7, name: 'Hired' }
]

export const JOB_POST_STAGE = [
  { id: 1, name: 'Applied', name_vn: 'Ứng tuyển' },
  { id: 3, name: 'Screening', name_vn: 'Lọc hồ sơ' },
  { id: 5, name: 'Interview', name_vn: 'Phỏng vấn' },
  { id: 6, name: 'Offer', name_vn: 'Đề nghị làm việc' },
  { id: 7, name: 'Hired', name_vn: 'Đã tuyển' }
]
export const employmentType = [
  { id: 0, name: 'Full time', name_vn: 'Toàn thời gian' },
  { id: 1, name: 'Part time', name_vn: 'Bán thời gian' },
  { id: 2, name: 'Internship', name_vn: 'Thực tập sinh' },
  { id: 5, name: 'Freelancer', name_vn: 'Làm việc tự do' },
  { id: 9, name: 'Remote work', name_vn: 'Làm việc từ xa' },
  { id: 6, name: 'Hybrid work', name_vn: 'Làm việc hybrid' }
]

export const ENABLE_ADS_FILLTER = [
  {
    id: 0,
    name: 'All'
  },
  {
    id: 1,
    name: 'Enable Ads'
  },
  {
    id: 2,
    name: 'Block Ads'
  }
]
export const NUMBER_LANGUAGE_MAP = {
  0: 'en',
  1: 'vi'
}

export const LIST_PLATFORM_NEW_ICON = {
  0: '/img/social/global.svg',
  1: '/img/social/linkedin.svg',
  2: '/img/social/github.svg',
  3: '/img/social/behance.svg',
  4: '/img/social/dribbble.svg',
  5: '/img/social/facebook.svg',
  6: '/img/social/twitter.svg',
  7: '/img/social/instagram.svg',
  8: '/img/social/youtube.svg',
  9: '/img/social/gitlab.svg'
}
