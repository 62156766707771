import { createSimpleEmitter } from '@legacy/@core/utilities/Emitter'
import { useEffect } from 'react'

const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter<unknown>()
export const emitApiForbidden = () => emitAll(undefined)
export const registApiForbidden = (callFuction) => {
  useEffect(() => {
    regiterCallback(callFuction)
    return () => {
      unregisterCallBack(callFuction)
    }
  }, [callFuction])
}
